// @ts-nocheck
// This file is generated by Umi automatically
// DO NOT CHANGE IT MANUALLY!
import React from 'react';

export async function getRoutes() {
  const routes = {"1":{"path":"/user","layout":false,"id":"1"},"2":{"path":"/user/login","layout":false,"name":"login","parentId":"1","id":"2"},"3":{"path":"/user","redirect":"/user/login","parentId":"1","id":"3"},"4":{"name":"register-result","icon":"smile","path":"/user/register-result","parentId":"1","id":"4"},"5":{"name":"register","icon":"smile","path":"/user/register","parentId":"1","id":"5"},"6":{"path":"/user/*","parentId":"1","id":"6"},"7":{"path":"/account","parentId":"ant-design-pro-layout","id":"7"},"8":{"name":"settings","path":"/account/settings","parentId":"7","id":"8"},"9":{"path":"/welcome","name":"welcome","icon":"smile","parentId":"ant-design-pro-layout","id":"9"},"10":{"name":"My Information","path":"/account/settings","icon":"user","parentId":"ant-design-pro-layout","id":"10"},"11":{"path":"/","redirect":"/welcome","parentId":"ant-design-pro-layout","id":"11"},"12":{"name":"Comment","icon":"message","path":"/comment/form","parentId":"ant-design-pro-layout","id":"12"},"13":{"name":"Comment List","icon":"table","path":"/comment/list","parentId":"ant-design-pro-layout","id":"13"},"14":{"name":"User List","icon":"table","path":"/user/list","access":"canAdmin","parentId":"ant-design-pro-layout","id":"14"},"15":{"name":"Group List","icon":"UnorderedList","path":"/group/list","access":"canAdmin","parentId":"ant-design-pro-layout","id":"15"},"16":{"name":"宿舍列表","icon":"UnorderedList","path":"/dormitory-group/list","access":"canAdmin","parentId":"ant-design-pro-layout","id":"16"},"17":{"name":"操作记录","icon":"history","path":"/grouplogs/list","access":"canAdmin","parentId":"ant-design-pro-layout","id":"17"},"18":{"path":"/announcement/publish","name":"发布通知","icon":"notification","access":"canAdmin","parentId":"ant-design-pro-layout","id":"18"},"19":{"path":"*","layout":false,"id":"19"},"ant-design-pro-layout":{"id":"ant-design-pro-layout","path":"/","isLayout":true}} as const;
  return {
    routes,
    routeComponents: {
'1': React.lazy(() => import('./EmptyRoute')),
'2': React.lazy(() => import(/* webpackChunkName: "p__User__Login__index" */'@/pages/User/Login/index.tsx')),
'3': React.lazy(() => import('./EmptyRoute')),
'4': React.lazy(() => import(/* webpackChunkName: "p__User__register-result__index" */'@/pages/User/register-result/index.tsx')),
'5': React.lazy(() => import(/* webpackChunkName: "p__User__register__index" */'@/pages/User/register/index.tsx')),
'6': React.lazy(() => import(/* webpackChunkName: "p__404" */'@/pages/404.tsx')),
'7': React.lazy(() => import('./EmptyRoute')),
'8': React.lazy(() => import(/* webpackChunkName: "p__AccountSettings__index" */'@/pages/AccountSettings/index.tsx')),
'9': React.lazy(() => import(/* webpackChunkName: "p__Welcome" */'@/pages/Welcome.tsx')),
'10': React.lazy(() => import(/* webpackChunkName: "p__AccountSettings__index" */'@/pages/AccountSettings/index.tsx')),
'11': React.lazy(() => import('./EmptyRoute')),
'12': React.lazy(() => import(/* webpackChunkName: "p__Comments__CommentForm" */'@/pages/Comments/CommentForm.tsx')),
'13': React.lazy(() => import(/* webpackChunkName: "p__Comments__CommentList" */'@/pages/Comments/CommentList.tsx')),
'14': React.lazy(() => import(/* webpackChunkName: "p__UserList__index" */'@/pages/UserList/index.tsx')),
'15': React.lazy(() => import(/* webpackChunkName: "p__Groups__index" */'@/pages/Groups/index.tsx')),
'16': React.lazy(() => import(/* webpackChunkName: "p__DormitoryGroups__index" */'@/pages/DormitoryGroups/index.tsx')),
'17': React.lazy(() => import(/* webpackChunkName: "p__GroupLogs__index" */'@/pages/GroupLogs/index.tsx')),
'18': React.lazy(() => import(/* webpackChunkName: "p__Announcement__index" */'@/pages/Announcement/index.tsx')),
'19': React.lazy(() => import(/* webpackChunkName: "p__404" */'@/pages/404.tsx')),
'ant-design-pro-layout': React.lazy(() => import(/* webpackChunkName: "t__plugin-layout__Layout" */'/opt/buildhome/repo/src/.umi-production/plugin-layout/Layout.tsx')),
},
  };
}
